import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutomationService } from 'src/app/core/services/automation.service';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';

@Component({
  selector: 'app-add-automation',
  templateUrl: './add-automation.component.html',
  styleUrls: ['./add-automation.component.scss']
})
export class AddAutomationComponent implements OnInit {
  ListSearch!: FormGroup;
  ConditionForm!: FormGroup;
  DragForm!: FormGroup;
  TriggerForm!: FormGroup;
  AddConditionForm!: FormGroup;
  keywordlist: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  managementType = ['Users', 'Contacts', 'Sites', 'Products', 'Transactions'];
  timeOptions = [{ v1: 0, v2: 'None' }, { v1: 1, v2: 'Minute' }, { v1: 2, v2: 'Hour' }, { v1: 3, v2: 'Day' }, { v1: 4, v2: 'Week' }, { v1: 5, v2: 'Month' }, { v1: 6, v2: 'Year' }];
  dayOccurrence = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ]
  ContactsValue: any[] = []
  ProductsValue: any[] = []
  SitesValue: any[] = []
  UsersValue: any[] = []
  EventsValue: any[] = []
  TransationValue: any[] = []
  stringoptions = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }];
  numberoptions = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }, { v1: '>', v2: 'greater than' }, { v1: '=>', v2: 'greater than or equal' }, { v1: '<', v2: 'less than ' }]
  textoptions = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }]
  booleanoptions = [{ v1: '=', v2: 'equal' }]
  AllOption = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }, { v1: '>', v2: 'greater than' },
  { v1: '<', v2: 'less than ' }, { v1: '=>', v2: 'greater than or equal' }, { v1: '<=', v2: 'less than or equal' }];
  maxValue: number = 60;
  selectedWaitDelay: string | undefined;
  today = new Date()
  EntityValue: any;
  filteredEvents:any = [];
  entityControl: any;

  constructor(
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<AddAutomationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
  ) {
    this.ListSearch = this.formBuilder.group({
      Name: ['', Validators.required],
      description: ['', Validators.required],
      keywords: ['', Validators.required],
      Status: ['0', Validators.required],
      Created: new Date().toISOString(),
    });
    this.DragForm = this.formBuilder.group({
      Name: ['', Validators.required],
      description: [''],
      ParentId: this.data.parentId,
      ChildId: this.data.id,
      showIcon: this.data.showIcon,
      showColor: this.data.showColor,
    });
    this.TriggerForm = this.formBuilder.group({
      entity: [''],
      event: [''],
      showIcon: this.data.showIcon,
      showColor: this.data.showColor,
    });
  
    this.ConditionForm = this.formBuilder.group({
      Name: ['', [Validators.required]],
      description: [''],
      isActive: [true],
      ParentId: this.data.parentId,
      ChildId: this.data.id,
      scheduledDateAndTime: [''],
      period: [''],
      duration: [''],
      Created: new Date().toISOString(),
      previousStep:  this.data.previousStep,
      step: this.data.step
    })
    // this.Addcondictuon()
    this.AddConditionForm = this.formBuilder.group({
      Name: ['', [Validators.required]],
      description: [''],
      ParentId: this.data.parentId,
      ChildId: this.data.id,
      showIcon: this.data.showIcon,
      showColor: this.data.showColor,
      scheduledDateAndTime: [''],
      duration: [''],
      period: [''],
      Trigger: [null, [Validators.required]],
      Conditions: this.formBuilder.array([]),
      previousStep:  this.data.previousStep,
      step: this.data.step
    })
    this.Addcondition()
  }

  Addcondictuon() {
    this.Conditions().push(this.newFile());
  }
  Removecondictuon(i: number) {
    this.Conditions().removeAt(i);
  }

  Addcondition() {
    this.Conditions1().push(this.newFile1());
  }
  RemoveCondition(i: number) {
    this.Conditions().removeAt(i);
  }

  Conditions1(): FormArray {
    return this.AddConditionForm.get("Conditions") as FormArray
  }

  newFile1(): FormGroup {
    return this.formBuilder.group({
      conditionParameter: [""],
      conditionComparison: [""],
      value: [''],
      isTag: [''],
      dataType: '',
    })
  }

  getentityvalue2() {
    return this.AddConditionForm.get('Trigger')?.value
  }

  selactdatatype(event:any,index:number) {
    console.log(event.dataType)
    this.Conditions1().at(index).get('dataType')?.setValue(event.dataType)
  }

  getdataType1(index:number) {
    const value = this.Conditions1().at(index).get('dataType')?.value
    return value
  }

  ngOnInit(): void {
    console.log(this.data)
    this.getEntity()
    this.getAllColoumns()
    this.entityControl = this.TriggerForm.get('entity');
    
  }

  Conditions(): FormArray {
    return this.TriggerForm.get("Conditions") as FormArray
  }

  newFile(): FormGroup {
    return this.formBuilder.group({
      conditionParameter: [""],
      conditionComparison: [""],
      value: [''],
      isTag: [''],
      dataType: '',
    })
  }

  getentityvalue1() {
    return this.TriggerForm.get('Trigger')?.value
  }

  getdataType(index:number) {
    const value = this.Conditions().at(index).get('dataType')?.value
    return value
  }

  ApplyAutomation() {
    // const formValues = this.ListSearch.value
    console.log(this.ListSearch)
    this.matDialogRef.close(this.ListSearch);
  }

  AddTrigger(){
    this.matDialogRef.close(this.TriggerForm);
  }

  
getAllColoumns() {
  this.FindImportColumnsUsers()
  this.FindImportColumnsContacts()
  this.FindImportColumnsSites()
  this.FindImportColumnsProducts()
  this.FindImportColumnsTransactions()
  this.FindImportColumnsEvents()
}

getEntity(){
  this.injector.get(AutomationService).FindEntity().subscribe((res: any) => {
   console.log(res)
   this.EntityValue = res
  })
}

onEntityChange(selectedEntity: any) {
  console.log(selectedEntity)
  // Filter events based on the selected entity
  if (selectedEntity && selectedEntity.events) {
    this.filteredEvents = selectedEntity.events;
  } else {
    this.filteredEvents = [];
  }

  // Reset the selected event whenever the entity changes
  this.TriggerForm.get('event').setValue('');

}

  FindImportColumnsUsers() {
    let name = 'Users'
    this.injector.get(ManageUsersService).FindParameters(name, false).subscribe((res: any) => {
      res.forEach((e: any) => {
        this.UsersValue.push(e)
      })
    })
  }

  FindImportColumnsContacts() {
    let name = 'Contacts'
    this.injector.get(ManageUsersService).FindParameters(name, false).subscribe((res: any) => {
      res.forEach((e: any) => {
        this.ContactsValue.push(e)
      })
    })
  }
  FindImportColumnsSites() {
    let name = 'Sites'
    this.injector.get(ManageUsersService).FindParameters(name, false).subscribe((res: any) => {
      res.forEach((e: any) => {
        this.SitesValue.push(e)
      })
    })
  }
  FindImportColumnsProducts() {
    let name = 'Products'
    this.injector.get(ManageUsersService).FindParameters(name, false).subscribe((res: any) => {
      res.forEach((e: any) => {
        this.ProductsValue.push(e)
      })
    })
  }

  FindImportColumnsTransactions() {
    let name = 'Transactions'
    this.injector.get(ManageUsersService).FindParameters(name, false).subscribe((res: any) => {
      res.forEach((e: any) => {
        this.TransationValue.push(e)
      })
    })
  }
  FindImportColumnsEvents() {
    let name = 'Events'
    this.injector.get(ManageUsersService).FindParameters(name, false).subscribe((res: any) => {
      res.forEach((e: any) => {
        this.EventsValue.push(e)
      })
    })
  }

  updateValidation(event:any) {
    if (event === 'Seconds' || event === 'Minutes') {
      this.maxValue = 60;
      this.ConditionForm.get('every')?.setValue('60');
    } else if (event === 'Hours') {
      this.maxValue = 24;
      this.ConditionForm.get('every')?.setValue('24');
    } else {
      this.maxValue = Infinity;
    }
    
  }
  onInputChange(event: Event) {
    const inputValue = (event.target as HTMLInputElement)?.valueAsNumber;
    // if (this.maxValue === 60 && inputValue > 60) {
    //   (event.target as HTMLInputElement)?.value = '60'; 
    // }
    // if (this.maxValue === 24 && inputValue > 24) {
    //   (event.target as HTMLInputElement)?.value = '24'; 
    // }
  }

  AddScenerio(){
    console.log(this.DragForm)
    this.matDialogRef.close(this.DragForm);
  }

  AddCondition(){
    this.matDialogRef.close(this.ConditionForm);
  }

  AddConditionSubmit(){
    this.matDialogRef.close(this.AddConditionForm);
  }
}

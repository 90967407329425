import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ManageContactsService } from 'src/app/core/services/manage-contacts.service';
import { ManageProductsService } from 'src/app/core/services/manage-products.service';
import { ManageSitesService } from 'src/app/core/services/manage-sites.service';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';

@Component({
  selector: 'app-advance-search-in-management-single',
  templateUrl: './advance-search-in-management-single.component.html',
  styleUrls: ['./advance-search-in-management-single.component.scss']
})
export class AdvanceSearchInManagementSingleComponent implements OnInit {
  singleSearchValue = ''
  ListSearch!: FormGroup
  filteredContacts: any;
  contacts$: any;
  filteredSites: any;
  sites$: any;
  products$: any;
  filteredProducts: any;
  filteredusers: any;
  users$: any;
  AllOption = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }, { v1: '>', v2: 'greater than' },
    { v1: '<', v2: 'less than ' }, { v1: '=>', v2: 'greater than or equal' }, { v1: '<=', v2: 'less than or equal' }];
  tags: any;
  constructor(
    private matDialogRef: MatDialogRef<AdvanceSearchInManagementSingleComponent>,
    private translate: TranslateService ,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    public datepipe: DatePipe,


  ) {
    if(localStorage.getItem('lang')){
      const lang:any=localStorage.getItem('lang')
      this.translate.use(lang);
    }else{
      this.translate.use('English');
    }

  }

  ngOnInit(): void {
    this.ListSearch = this.formBuilder.group({
      Name: [null],
      companyName: [null],
      EmailId: [null], 
      Gender: [null],
      Position:[null],
      Address: [null],
      City:[null],
      Country:[null],
      ZipCode: [null],
      UsersId:[''],
      ContactsId: [''],
      SitesId: [''],
      ProductsId: [''],
      ProductDescription:[''],
      ProductReference: [''],
      StockQuantity: [''],
      condition: [''],
      DiscountStatus: [''],
      StartDate: [''],
      EndDate: [''],
      DiscountCode: [''],
      DiscountDescription: [''],
      strategyConditions: this.formBuilder.array([
      ])
    });
    this.Addcondictuon()
    this.getallusers()
    this.getallContacts()
    this.getallSites()
    this.getallProducts()
    this.getTags()
  }


  getallusers(){
    // get all users
    this.injector.get(ManageUsersService).findAllUsersDropdown().subscribe((result) => {
      if (result) {
        this.users$ = result;
        this.filteredusers = result;
      }
    });
  } 

  getallContacts(){
    // get all contacts
    this.injector.get(ManageContactsService).findAllContactsDropdown().subscribe((result) => {
      if (result) {
        this.contacts$ = result;
        this.filteredContacts = result;
      }
    });
  } 

  getallSites(){
    // get all sites
    this.injector.get(ManageSitesService).findAllSitesDropdown().subscribe((result) => {
      if (result) {
        this.sites$ = result;
        this.filteredSites = result;
      }
    });
  } 
  getallProducts(){
    // get all sites
    this.injector.get(ManageProductsService).findAllProductsDropdown().subscribe((result) => {
      if (result) {
        this.products$ = result;
        this.filteredProducts = result;
      }
    });
  } 
  filterOptions(value: string, type: string): void {
    console.log(type);
      this.filteredContacts = this.contacts$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
      this.filteredSites = this.sites$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
      this.filteredProducts = this.products$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
      this.filteredusers = this.users$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
  }

  getTags() {
    this.injector.get(ManageUsersService).FindAllTags(this.data.type).subscribe(res => {
      this.tags = res
    })
  }

  selactdatatype(event:any,index:number) {
    this.strategyConditions().at(index).get('dataType')?.setValue(event.dataType)
    this.strategyConditions().at(index).get('isTag')?.setValue(event.isTag)
  }
  getdataType(index:number){
    const value =this.strategyConditions().at(index).get('dataType')?.value
    return value
  }
  strategyConditions(): FormArray {
    return this.ListSearch.get("strategyConditions") as FormArray
  }

  getconditionDropDownListvalue(index:number){
    return this.strategyConditions().at(index).get('conditionDropDownList')?.value
  }
  newFile(): FormGroup {
    return this.formBuilder.group({
      tagColumnId: [0],
      condition: [""],
      value: [''],
    })
  }

  Addcondictuon() {
    this.strategyConditions().push(this.newFile());
  }
  Removecondictuon(i: number) {
    this.strategyConditions().removeAt(i);
  }

  submit() {
    const formValues = this.ListSearch.value;

    // Filter out empty strategy conditions
    const validStrategyConditions = formValues.strategyConditions.filter((condition: any) => {
        return condition.tagColumnId || condition.condition || condition.value;
    });

    // Build the payload with non-empty form values
    const payload: any = {};

    // Include main form values only if they are not null or empty
    for (const key in formValues) {
        if (formValues[key] !== null && formValues[key] !== '' && key !== 'strategyConditions') {
          if (key === 'StartDate' || key === 'EndDate') {
            payload[key] = this.datepipe.transform(formValues[key], 'dd-MMM-yyyy'); // Format the date
          } else {
            payload[key] = formValues[key];
          }
        }
    }

    // Conditionally add tagFilter and strategyConditions to the payload
    if (validStrategyConditions.length > 0) {
        validStrategyConditions.forEach((condition: any, index: number) => {
            payload[`TagFilter[${index}].TagColumnId`] = condition.tagColumnId;
            payload[`TagFilter[${index}].Condition`] = condition.condition;
            payload[`TagFilter[${index}].Value`] = condition.value;
        });
    }
    console.log(payload); // For debugging

    // Send the payload through your service or close the dialog
    this.matDialogRef.close(payload);
}

  

}

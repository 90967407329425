import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { error } from 'console';
import { GoogleApiService } from 'src/app/core/services/SYNC/google-api.service';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { DriveService } from 'src/app/core/services/drive.service';
import { FileService } from 'src/app/core/services/file.service';
import { GraphService } from 'src/app/core/services/graph.service';
import { ManageContactsService } from 'src/app/core/services/manage-contacts.service';
import { ManageProductsService } from 'src/app/core/services/manage-products.service';
import { ManageSitesService } from 'src/app/core/services/manage-sites.service';
import { ManageTransactionsService } from 'src/app/core/services/manage-transactions.service';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { MessageService } from 'src/app/core/services/message.service';
import { StrategyService } from 'src/app/core/services/strategy.service';
import { PopupErrorMessageComponent } from 'src/app/popup-error-message/popup-error-message.component';
import { ReminderCreateComponent } from '../../Reminder/reminder-create/reminder-create.component';

@Component({
  selector: 'app-popup-common',
  templateUrl: './popup-common.component.html',
  styleUrls: ['./popup-common.component.scss']
})
export class PopupCommonComponent implements OnInit {
  screenType: any;
  entitydata: any;
  DemoOrganation:boolean=false
  message=null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private MatDialogRefDelet: MatDialogRef<PopupCommonComponent>,
    public snackBar: MatSnackBar,
    private manageContactsService: ManageContactsService,
    private managesitesService: ManageSitesService, 
    private manageproductsService: ManageProductsService,
    private manageUserService: ManageUsersService,
    private calendarService: CalendarService,
    private fileService: FileService,
    private strategyservice: StrategyService,
    private ManageTransactionsService: ManageTransactionsService,
    private graphService: GraphService,
    private DriveService: DriveService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private goole: GoogleApiService,
  ) {
    if(localStorage.getItem('DemoOrganation')==='true'){
this.DemoOrganation==true
    }else{
      this.DemoOrganation==false
    }
   }

  ngOnInit(): void {
    this.screenType = this.data?.screenType
    this.entitydata = this.data.data;
    console.log(this.data)
  }


  onSubmit(){
    if(this.screenType=='Accpet'){

      this.MatDialogRefDelet.close(this.message)
    }else if(this.screenType=='Comment'){
      this.MatDialogRefDelet.close(this.message)
    }

  }







}

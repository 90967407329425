import { Component, Inject, OnInit } from '@angular/core';
import { GoogleServicesService } from 'src/app/core/services/SYNC/google-services.service';
import { MicrosoftServicesService } from 'src/app/core/services/SYNC/microsoft-services.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-sync-options-list',
  templateUrl: './sync-options-list.component.html',
  styleUrls: ['./sync-options-list.component.scss']
})
export class SyncOptionsListComponent implements OnInit {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  spinner=false;
  screentype=''
  readonly Gmail = false
  readonly Drive = false
  readonly GoogleCalenders = false
  readonly OneDrive = false
  readonly OutLook = false
  readonly OutLookCalender = false
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<SyncOptionsListComponent>,
    private Google:GoogleServicesService,
    private dialog: MatDialog,
    private Microsoft: MicrosoftServicesService,
    

  ) { }

  ngOnInit(): void {
    this.screentype=this.data.screentype
  }

  selectservice(type){
    this.SynchronisationComponent1(type)
  }

  



  SynchronisationComponent1(type) {
    this.dialog.open(SyncOptionsListComponent, {
      data:{screentype:type},
      autoFocus: false,
      disableClose: true,
    });
  }

  Sync(){
    if(this.screentype=='Googleservice'){
      console.log('Gmail',this.Gmail)
      console.log('Drive',this.Drive)
      console.log('GoogleCalenders',this.GoogleCalenders)
      if(this.Gmail&&!this.Drive&&!this.GoogleCalenders){
        this.Google.mailloginpage()
      }else if(!this.Gmail&&this.Drive&&!this.GoogleCalenders){
        this.Google.driveloginpage()
      }else if (!this.Gmail&&!this.Drive&&this.GoogleCalenders){
        this.Google.calenderloginpage()
      }else if(this.Gmail&&this.Drive&&!this.GoogleCalenders){
        this.Google.mail_drive_loginpage()
      }else if ( this.Gmail&&this.Drive&&this.GoogleCalenders){
        this.Google.mail_drive_Calendar_loginpage()
      }else if (this.Gmail&&!this.Drive&&this.GoogleCalenders){
        this.Google.mail_Calendar_loginpage()
      }
    }else if( this.screentype=='Mircosaftservice'){
      console.log('OneDrive',this.OneDrive)
      console.log('OutLook',this.OutLook)
      console.log('OutLookCalender',this.OutLookCalender)
      if(this.OutLook&&!this.Drive&&!this.GoogleCalenders){
        this.Microsoft.outlookloginpage()
      }else if(!this.OutLook&&this.OneDrive&&!this.OutLookCalender){
        this.Microsoft.onedriveloginpage()
      }else if (!this.OutLook&&!this.OneDrive&&this.OutLookCalender){
        this.Microsoft.Calendarloginpage()
      }else if(this.OutLook&&this.OneDrive&&!this.OutLookCalender){
       this.Microsoft.outlook_Calendar_loginpage()
      }else if ( this.OutLook&&this.OneDrive&&this.OutLookCalender){
      this.Microsoft.outlook_onedrive_Calendar_loginpage()
      }else if (this.OutLook&&!this.OneDrive&&this.OutLookCalender){
       this.Microsoft.outlook_onedrive_loginpage()
      }
    }

  }




}

<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title *ngIf="screenType=='Accpet'" class="m-0 dialog-title text-center w-100">Confirmation</h2>
    <h2 mat-dialog-title *ngIf="screenType=='Comment'" class="m-0 dialog-title text-center w-100">Confirmation</h2>
  </div>

  <mat-dialog-content class="mr-0 ml-0">

<ng-container *ngIf="screenType=='Accpet'" >
  <div class="row mt-4">
    <div class="col-12">
      <p style="font-size: 18px;">Are you sure you want to <img src="../../../assets/images/iconsForsvg/approval.svg" alt=""> the request for <br> <mat-icon style="color: green;">assignment_turned_in</mat-icon><b>{{entitydata?.entityName}}</b> ?</p>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="screenType=='Comment'" >
  <div class="row mt-4">
    <div class="col-12">
      <p style="font-size: 18px;">Are you sure you want to send a <mat-icon>message</mat-icon> regarding the <br><mat-icon style="color: green;">assignment_turned_in</mat-icon><b>{{entitydata?.entityName}}</b> ?</p>
    </div>
  </div>
  <div class="row w-100">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Message</mat-label>
        <textarea matInput [(ngModel)]="message" name="message" rows="4"></textarea>
      </mat-form-field>
</div>
</ng-container>



  </mat-dialog-content>

  <mat-dialog-actions align="end" class="my-1">
    <div class="row  justify-content-center">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button class="w-100 b-blue" (click)="onSubmit()">{{"Apply"|translate}}</button>
        </div>
    </div>
</mat-dialog-actions>
<div class="User-details">
  <div class="d-flex flex-row align-items-center modal-header1">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
      {{'Activity Log'|translate}}
    </h2>
    <mat-icon (click)="opensearch()">filter_list</mat-icon>
    <mat-icon (click)="closeDialog()" class="mx-4">close</mat-icon>
  </div>
  <mat-dialog-content class="mr-0 ml-0">
    <div class="row mt-2" *ngIf="ActivitLogdata.length !=0">
      <h2 style="margin-right: 3px;"> Activity Log For </h2>
      <img class="avatar" style="border-radius: 50%;"
      *ngIf="DialogData?.profilePicture"
        [src]="DialogData?.profilePicture">
      <span
        *ngIf="((DialogData?.profilePicture == '' || DialogData?.profilePicture == null) && (data.type == 'Users' || data.type == 'Contacts'))"
        [style.background]="getColor(4)"
        style="color: white; padding: 7px;border-radius: 50%;position: relative; top: -5px;margin: 5px;width: 32px;height:32px;">
        {{getuserFLnames(DialogData.firstName+' '+ DialogData.lastName)}}
      </span>
      <span *ngIf="data.type == 'Sites'">
        <img style="width: 30px; height: 30px;" src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
      </span>
      <span *ngIf="(data.type == 'Products')">
        <img style="width: 30px; height: 30px;"
          [src]="DialogData?.profilePicture ? DialogData?.profilePicture : '../../../../../assets/images/iconsForsvg/icon_product.svg'">
      </span>
      <mat-icon *ngIf="this.data.type == 'Transactions'" style="color:rgb(100, 149, 237)"> shopping_cart</mat-icon>
      <mat-icon *ngIf="this.data.type == 'Orders'" style="color:rgb(100, 149, 237)"> assignment</mat-icon>
      <mat-icon *ngIf="this.data.type == 'Tasks' && this.DialogData.isMileStone == false" style="color:green">
        assignment_turned_in</mat-icon>
      <mat-icon *ngIf="this.data.type == 'Initiatives'" style="color:red"> ballot</mat-icon>
      <span *ngIf="this.data.type == 'Tasks' && this.DialogData.isMileStone == true">
        <img style="width: 30px; height: 30px;"
          src="../../../../assets/images/iconsForsvg/nearby_FILL0_wght400_GRAD0_opsz24.svg">

      </span>
      <span *ngIf="this.data.type == 'Automation'">
        <img style="width: 30px; height: 30px;" src="../../../../assets/images/iconsForsvg/setting-automation.svg">
      </span>
      <h2 *ngIf="this.data.type == 'Users' || this.data.type == 'Contacts'">{{DialogData.firstName|titlecase}}
        {{DialogData.lastName|titlecase}}</h2>
      <h2 *ngIf="this.data.type == 'Sites'">{{DialogData.companyName|titlecase}}</h2>
      <h2 *ngIf="this.data.type == 'Products'">{{DialogData.productName|titlecase}}</h2>
      <h2 *ngIf="this.data.type == 'Transactions'">{{DialogData.transactionReference}}</h2>
      <h2 *ngIf="this.data.type == 'Orders'">{{DialogData.orderId}}</h2>
      <h2 *ngIf="this.data.type == 'Tasks'">{{DialogData.title}}</h2>
      <h2 *ngIf="this.data.type == 'Initiatives'">{{DialogData.title}}</h2>
      <h2 *ngIf="this.data.type == 'Automation'">{{DialogData.name}}</h2>
    </div>
    <ng-container *ngIf="ActivitLogdata.length ==0 || ActivitLogdata.length == ''">
      <div class="row mt-5">
        <div class="col-12 p-0 nodata_text">
          <span>No Activity Logs for this {{data.Entity == 'User' ? 'User' : data.Entity == 'Contacts' ? 'Contact' :
            data.Entity == 'Sites' ? 'Site' : data.Entity == 'Product' ? 'Product' : data.Entity == 'Transaction' ?
            'Transaction' : data.Entity == 'Task' ? 'Task' : 'Initiative'}}
            <img
              *ngIf="((DialogData?.profilePicture) && (data.type == 'Users' || data.type == 'Contacts' || data.type == 'Products'))"
              class="avatar" style="border-radius: 50%;margin-top: -8px;"
              [src]="DialogData?.profilePicture ? DialogData?.profilePicture : DialogData?.profile">
            <span
              *ngIf="((DialogData?.profilePicture === null || DialogData?.profilePicture === '') && (data.type == 'Users' || data.type == 'Contacts'))"
              [style.background]="getColor(4)"
              style="color: white; padding: 8px;border-radius: 50%;position: relative; top: -8px;margin: 5px">
              {{getuserFLnames(DialogData.firstName+' '+DialogData.lastName)}}
            </span>
            <span *ngIf="data.type == 'Sites'">
              <img style="width: 30px; height: 30px;" src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
            </span>
            <span *ngIf="((data.type == 'Products') && (DialogData.profile == null))">
              <img style="width: 30px; height: 30px;"
                [src]="DialogData?.profilePicture ? DialogData.profilePicture : '../../../../../assets/images/iconsForsvg/icon_product.svg'">
            </span>
            <mat-icon *ngIf="this.data.type == 'Transactions'" style="color:rgb(100, 149, 237)">
              shopping_cart</mat-icon>
            <mat-icon *ngIf="this.data.type == 'Orders'" style="color:rgb(100, 149, 237)"> assignment</mat-icon>
            <mat-icon *ngIf="this.data.type == 'Tasks' && this.DialogData.isMileStone == false" style="color:green">
              assignment_turned_in</mat-icon>
            <mat-icon *ngIf="this.data.type == 'Initiatives'" style="color:red"> ballot</mat-icon>
            <span *ngIf="this.data.type == 'Tasks' && this.DialogData.isMileStone == true">
              <img style="width: 30px; height: 30px;"
                src="../../../../assets/images/iconsForsvg/nearby_FILL0_wght400_GRAD0_opsz24.svg">

            </span>
            <b *ngIf="this.data.type == 'Users' || this.data.type == 'Contacts'">{{DialogData.firstName + ' ' +
              DialogData.lastName}}</b>
            <b *ngIf="this.data.type == 'Sites'">{{DialogData.companyName|titlecase}}</b>
            <b *ngIf="this.data.type == 'Products'">{{DialogData.productName|titlecase}}</b>
            <b *ngIf="this.data.type == 'Transactions'">{{DialogData.transactionReference}}</b>
            <b *ngIf="this.data.type == 'Orders'">{{DialogData.orderId}}</b>
            <b *ngIf="this.data.type == 'Tasks'">{{DialogData.title}}</b>
            <b *ngIf="this.data.type == 'Initiatives'">{{DialogData.title}}</b>
          </span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="ActivitLogdata.length !=0 || ActivitLogdata.length != ''">
      <!-- <div class="row mt-2">
              <div class="col-12 p-0">
                  <p style="text-align: center;">No Data Found</p>
                  </div>
                  </div> -->
      <div *ngFor="let x of ActivitLogdata;let index=index;">
        <div
          style="background-color: #e2f2e6;display: flex;border-bottom: 1px solid black;border-top: 1px solid black;">
          <div style="width:12%;">
            <mat-icon *ngIf="x.entity == 'Task'" style="color:green;padding: 12px;"> assignment_turned_in</mat-icon>
            <mat-icon *ngIf="x.entity == 'User'" style="color:#fb4444;padding: 12px;"> person</mat-icon>
            <mat-icon *ngIf="x.entity == 'Event'" style="color:#fb4444;padding: 12px;"> event</mat-icon>
            <mat-icon *ngIf="x.entity == 'Contacts'" style="color:#6495ed;padding: 12px;"> contacts</mat-icon>
            <img *ngIf="x.entity == 'Sites'" src="../../../../../assets/images/iconsForsvg/Site_Icon.svg"
              style="width: 25px; height: 25px; margin-top: 8px; margin-left: 6px;">
            <mat-icon *ngIf="x.entity == 'Product'" style="color:#fb4444;padding: 12px;"> business_center</mat-icon>
            <mat-icon *ngIf="x.entity == 'Initiative'" style="color:#fb4444;padding: 12px;"> ballot</mat-icon>
            <mat-icon *ngIf="x.entity == 'Transaction' || x.entity == 'Order'" style="color:#6495ed;padding: 12px;">shopping_cart</mat-icon>
            <mat-icon *ngIf="x.entity == 'Note'" style="color:#ffc16f;padding: 12px;">speaker_notes</mat-icon>
          </div>
          <div style="width:50%;display: flex;">
            <span *ngIf="x.profilePicture == null || x.profilePicture == ''" [style.background]="getColor(index)"
              style="color: white;padding: 7px;border-radius: 50%;position: relative;margin: 8px;background: rgb(40, 116, 166);font-size: 14px;width: 32px;height: 32px;">
              {{getuserFLnames(x.title)}}</span>
            <!-- <img *ngIf="x.profilePicture != null || x.profilePicture != ''" class="user_profile"  style="width:40px;height:40px;border-radius:50%;"
                          [src]="x.profilePicture" /> -->
            <p class="user_name" style="font-weight:600;margin-top:12px;font-size: 14px;">{{x.title}}</p>
          </div>
          <div style="width: 35%;">
            <div class="row w-100" style="margin-top:12px">
              <p style="font-size: 12px;margin-right:8px;">{{ x.logTime | date: 'dd-MMM-yyyy hh:mm:ss' }}</p>
            </div>
          </div>
          <div style="width: 15%;">
            <div class="row w-100" style="margin-top:12px">
              <button [matMenuTriggerFor]="beforeMenu" style="display: flex;justify-content: end;position: relative;" class="mr-1 a-color">
                <mat-icon class="icon-options">more_vert</mat-icon>
            </button>
            <mat-menu  #beforeMenu="matMenu" xPosition="before">
              <button *ngIf="x.entity == 'Event'" class="fw-600" mat-menu-item
              (click)="openEditdEventDialog(x?.entityId)">
                  <mat-icon>edit</mat-icon> {{'Edit'|translate}}
              </button>
              <button *ngIf="x.entity == 'Event'" class="fw-600" mat-menu-item (click)="deleteDialogConfirmation(eventData,'deleteEvent')">
                <mat-icon>delete</mat-icon> {{'Delete'|translate}}
              </button>
              <button *ngIf="(AdminStatus=='true' && x.entity == 'User')" class="fw-600" mat-menu-item 
                  (click)="updateuserDialog(x.entityId)">
                  <mat-icon>edit</mat-icon> {{'Edit'|translate}}
              </button>
              <button *ngIf="(AdminStatus=='true' && x.entity == 'User')" class="fw-600" mat-menu-item
                  (click)="deleteDialogConfirmation(x,'deleteUser')">
                  <mat-icon>delete</mat-icon> {{'Delete'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item (click)="openAddEventDialog()">
                  <mat-icon>event</mat-icon> {{'Create an Event'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item (click)="ActivityReport(x,true, 'Users')">
                  <mat-icon style="color:green">bar_chart</mat-icon> {{'Create an Activity Report'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item (click)="CreateStratergy()">
                  <mat-icon>wb_incandescent</mat-icon> {{'Create Stratergy'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item>
                  <mat-icon>vpn_key</mat-icon> {{'Edit Permission'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item (click)="AddFile(x,'single')">
                  <mat-icon style="color:rgb(66,135,245) ;">
                      insert_drive_file</mat-icon>
                  {{'Add File'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item (click)="AddNote(x,'single')">
                  <mat-icon style="color:rgb(255,193,111) ;">
                      speaker_notes </mat-icon>
                  {{'Add Note'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item (click)="createTarget(x)">
                  <mat-icon>track_changes</mat-icon> {{'Create Target'|translate}}
              </button>

              <button class="fw-600" mat-menu-item *ngIf="(AdminStatus=='true' && x.entity == 'Contacts')"
                        (click)="updateContactDialog(x.entityId)">
                        <mat-icon>edit</mat-icon> {{"Edit"|translate}}
                    </button>
                    <button class="fw-600" mat-menu-item *ngIf="(AdminStatus=='true' && x.entity == 'Contacts')"
                        (click)="deleteDialogConfirmation(x,'deleteContact')">
                        <mat-icon>delete</mat-icon> {{"Delete"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item (click)="openAddEventDialog()">
                        <mat-icon>event</mat-icon> {{"Create an Event"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item (click)="ActivityReport(x,true, 'Contacts')">
                        <mat-icon style="color:green">bar_chart</mat-icon> {{"Create an Activity Report"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item (click)="CreateStratergy()">
                        <mat-icon>wb_incandescent</mat-icon> {{"Create Stratergy"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item>
                        <mat-icon class="yellow">assignment</mat-icon> {{"Create Quote"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600 " mat-menu-item>
                        <mat-icon class="blue">assignment</mat-icon>{{"Create Invoice"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item (click)="AddFile(x,'single')">
                        <mat-icon style="color:rgb(66,135,245) ;">
                            insert_drive_file</mat-icon>
                        {{"Add File"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item (click)="AddNote(x,'single')">
                        <mat-icon style="color:rgb(255,193,111) ;">
                            speaker_notes </mat-icon>
                        {{"Add Note"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item (click)="createTarget(x)">
                        <mat-icon>track_changes</mat-icon> {{"Create Target"|translate}}
                    </button>
          </mat-menu>
            </div>
          </div>
        </div>
        <div class="rich-text mt-2 mb-2" style="margin:10px">
          <p style="font-weight:500">{{x.message}}</p>

        </div>
      </div>
    </ng-container>

  </mat-dialog-content>

  <div class="overlay" *ngIf="spinnerLoader">
    <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
  </div>
<div class="User-details" *ngIf="productData.type === 'product'">
    <div class="d-flex flex-row align-items-center modal-header">
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
            <mat-icon style="position: relative;right: 10px;"> business_center</mat-icon>
            {{"Product Details"|translate}}
        </h2>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <mat-dialog-content *ngIf="product" class="mr-0 ml-0">
        <div class="row m-t-14">
            <div class="col-4">
                <div *ngFor="let x of product.yearTargetDtos">
                    <div style="text-align: center; position: relative; top: -16px; left:-9px;">
                        <p style="margin: 0; font-size:10px ;position: relative; top:3px;">{{"Year"|translate}} {{x.year}}</p>
                        <button class="fw-600" style="border-bottom: none;padding: -1; border-left: none; border-right: none;
                         background-color: transparent; border-top: 2px solid rgb(185, 230, 249);font-size:10px  ">
                            {{ transform(x.saleAmount) +'€'}}
                            <!-- IsBaseUnitIsPercentage -->
                            <!-- IsSellingPriceIsPercentage -->
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="avatar-70 " style="display: flex;">
                    <img class="avatar-70"
                        [src]="product.profilePicture ? product.profilePicture:'assets/images/iconsForsvg/icon_product.svg'"
                        onError="this.src='assets/images/iconsForsvg/icon_product.svg'">
                </div>
            </div>
            <div class="col-4">
                <button  [matMenuTriggerFor]="beforeMenu" style="display: flex;justify-content: end;position: relative;left: 50px;" class="mr-1 b-color">
                    <mat-icon class="icon-options">more_vert</mat-icon>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">

                    <button class="fw-600" mat-menu-item *ngIf="(AdminStatus=='true'||product?.isCreator==true)"
                        (click)="updateproductDialog(product)">
                        <mat-icon>edit</mat-icon> {{"Edit"|translate}}
                    </button>
                    <button class="fw-600" mat-menu-item *ngIf="(AdminStatus=='true'||product?.isCreator==true)"
                        (click)="deleteDialogConfirmation(product,'deleteProduct')">
                        <mat-icon>delete</mat-icon> {{"Delete"|translate}}
                    </button>
                    <button class="fw-600" mat-menu-item (click)="openAddEventDialog()">
                        <mat-icon>event</mat-icon> {{"Create an Event"|translate}}
                    </button>
                    <button class="fw-600" mat-menu-item (click)="ActivityReport(product,true)">
                        <mat-icon style="color:green">bar_chart</mat-icon> Create an Activity Report
                    </button>
                    <button class="fw-600" mat-menu-item (click)="CreateStratergy()">
                        <mat-icon>wb_incandescent</mat-icon> {{"Create Stratergy"|translate}}
                    </button>
                    <button class="fw-600 " mat-menu-item (click)="CreateTransaction()">
                        <mat-icon class="blue">assignment</mat-icon>{{"Create Transation"|translate}}
                    </button>
                    <button class="fw-600" mat-menu-item (click)="AddFile(product,'single')">
                        <mat-icon style="color:rgb(66,135,245) ;">
                            insert_drive_file</mat-icon>
                        {{"Add File"|translate}}
                    </button>
                    <button class="fw-600" mat-menu-item (click)="AddNote(product,'single')">
                        <mat-icon style="color:rgb(255,193,111) ;">
                            speaker_notes </mat-icon>
                        {{"Add Note"|translate}}
                    </button>
                    <button class="fw-600" mat-menu-item (click)="createTarget()">
                        <mat-icon>track_changes</mat-icon> {{"Create Target"|translate}}
                    </button>
                    <button class="fw-600 " mat-menu-item>
                        <mat-icon class="green">loyalty</mat-icon> {{'Add Discount'|translate}}
                    </button>

                </mat-menu>
            </div>
        </div>
        <!-- show productName  -->
        <div class="row   justify-content-center">
            <h2 class="name_red" style="text-align: center;"> {{product.productName|titlecase }}</h2>
        </div>
        <!-- show Discount %  -->
        <div class="container">
            <div class="row mb-2 justify-content-md-center justify-content-center">
                <ng-container *ngIf="product.privateDiscountAmount!=null">
                    <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6" *ngIf="product.privateDiscountAmount!=0">
                        <span class="discountpercentageprivate">Discount :
                            {{product?.privateDiscountAmount?product.privateDiscountAmount:0}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="product.publicDiscountAmount!=null">
                    <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6" *ngIf="product.publicDiscountAmount!=0">
                        <span class="discountpercentagepublic">{{'Discount'|translate}} : {{product?.publicDiscountAmount ?
                            product.publicDiscountAmount:0}}</span>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Show CurrentYearTargetValue -->
        <ng-container *ngIf="getCurrentYearTargetValue.length !=0">
            <div class="row w-100" *ngFor="let targetvalue of getCurrentYearTargetValue;">
                <div class="row mt-3">
                    <div class="col-1 p-0">
                        <img class="avatar" style="border-radius: 50% !important;"
                            [src]="targetvalue?.creatorProfile ? targetvalue.creatorProfile:'assets/avatar.svg'"
                            onError="this.src='assets/avatar.svg'">
                    </div>
                    <div class="col-11 p-0 mt-2">
                        <span style="color: blue; font-style: italic;">{{targetvalue.creatorName}} </span>
                        <span style="margin-left: 5px;">
                            <mat-icon *ngIf="targetvalue.isPrivate" style="font-size: 20px;">lock</mat-icon>
                            <img *ngIf="targetvalue.isPublic" src="../../../../../assets/images/iconsForsvg/Unlock.svg"
                                alt="">
                            <mat-icon *ngIf="targetvalue.isDelegate" class="green"
                                style="font-size: 20px;">device_hub</mat-icon>
                        </span>
                    </div>
                </div>
                <div class="row p-0">
                    <div class="col-4 p-0">
                        <div class="row m-t-14">
                        </div>
                        <div class="row f-weight m-t-22">
                            <div>{{"Target"|translate}} For {{targetvalue.month}} {{targetvalue.year}} </div>
                            <div style="color: green !important;" *ngIf="targetvalue.percentage>99"><b>+{{targetvalue.percentage}}% PERFORMANCE</b></div>
                        </div>

                    </div>
                    <div class="col-8 p-0">
                        <div class="row ">
                            <div class="col-5"></div>
                            <p style="position: relative; top: 15px;" class="f-weight"> {{targetvalue.percentage>100?100:targetvalue.percentage}} %
                            </p>
                        </div>

                        <div class="row m-t-10">
                            <mat-progress-bar [value]="targetvalue.percentage"
                                [ngClass]="assignCssClass(targetvalue.percentage)">
                            </mat-progress-bar>
                        </div>
                        <div class="row m-t-10 ">
                            <div class="col-2"></div>
                            <div class=" fw-600 color_grey">{{transform(targetvalue.achievedAmount)+' '+'€'}}
                                /{{transform(targetvalue.target)+' '+'€'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <section class="detail-wrapper">
            <mat-list>
                <mat-list-item *ngIf="product.productDescription != null">
                    <div class="row w-100">
                        <div class="col-5 p-0">
                            <div class="fw-600">{{"Product Description"|translate}} : </div>
                        </div>
                        <div class="col-7 justify-content-start p-0">
                            <span class=" fw-600 color_grey">{{product.productDescription|titlecase}}</span>
                        </div>
                    </div>
                </mat-list-item>
                <mat-list-item *ngIf="product.productReference != null">
                    <div class="fw-600">{{"Product Reference"|translate}} : </div>
                    <span class="ml-3 fw-600 color_grey">{{product.productReference}}</span>
                </mat-list-item>
                <mat-list-item *ngIf="product.stockQuantity != null">
                    <div class="fw-600">{{"Stock Quantity"|translate}} : </div>
                    <span class="ml-3 fw-600 color_grey">{{product.stockQuantity+' '+product.measureName}}</span>
                </mat-list-item>
                <mat-list-item *ngIf="product.sellingPrice != null">
                    <div class="fw-600">{{"Selling Price"|translate}} : </div>
                    <span class="ml-3 fw-600 color_grey">
                        {{transform(product.sellingPrice)}} {{product.currenyName|titlecase}}
                        <mat-icon (click)="GetBaseUnitPriceHistory(product,'sellingPrice')">touch_app</mat-icon></span>
                </mat-list-item>
                <mat-list-item *ngIf="product.baseUnitPrice != null">
                    <div class="fw-600">{{"Base Unit Price"|translate}} : </div>
                    <span class="ml-3 fw-600 color_grey">
                        {{transform(product.baseUnitPrice)}} {{product.currenyName|titlecase}}
                        <mat-icon (click)="GetBaseUnitPriceHistory(product,'baseUnitPrice')">touch_app</mat-icon></span>
                </mat-list-item>
                <mat-list-item >
                    <div class="fw-600">{{"Price Matrix"|translate}} : </div>
                    <span class="ml-3 fw-600 color_grey">
                        View
                        <mat-icon (click)="PriceMatrix(product)">touch_app</mat-icon></span>
                </mat-list-item>
                <mat-list-item *ngIf="product.stockTotalPrice != null">
                    <div class="fw-600">{{"Stock Total Price"|translate}} : </div>
                    <span class="ml-3 fw-600 color_grey">
                        {{transform(product.stockTotalPrice)}} {{product.currenyName|titlecase}}</span>
                </mat-list-item>
                <mat-list-item *ngIf="product.comments != null">
                    <div class="fw-600 " style="width: 30%;">{{"Comments"|translate}} : </div>
                    <span class="ml-3 fw-600 color_grey">
                     {{product.comments|titlecase}}</span>
                </mat-list-item>
                <!-- Users -->
                <mat-list-item>
                    <div class="fw-600">
                        <mat-icon class="red vertical-bottom">person</mat-icon> {{"Users"|translate}} :
                    </div>
                    <span class="ml-3 fw-600 color_grey m-t-6 pointer"
                        [ngClass]="{'color_grey': product?.usersCount > 0,'name_red': product.usersCount == 0 }"
                        (click)="findUserLinked(product.usersCount,'Users')">
                        {{product.usersCount}} {{product.usersCount > 1 ? 'Users' : 'User'}}</span>
                </mat-list-item>
                <div class="col-10 m-t-10  " *ngIf="showUser"
                    [ngClass]="{'auto_height': product.usersCount > 4,'overflow': product.usersCount < 4 }">
                    <div class="row float-l pointer m-t-20 fw-600 color_grey"
                        *ngFor="let user of getuser ; let i = index">
                        <span class="m-l-28"><img style="border-radius: 50%;" width="30" height="30"
                                (click)="ShowUserDEtails(user, 'user')"
                                [src]="user.profile ? user.profile :'assets/avatar.svg'"></span><span
                            (click)="ShowUserDEtails(user, 'user')" class="p-l-10">
                            {{user.firstName|titlecase}} {{user.lastName|titlecase}}</span>
                    </div>
                </div>
                <!-- Contacts -->
                <mat-list-item>
                    <div class="fw-600">
                        <mat-icon class="vertical-bottom" style="color: #6495ed;">contacts</mat-icon> {{'Contacts'|translate}} :
                    </div>
                    <span class="ml-3 fw-600 color_grey m-t-6 pointer"
                        [ngClass]="{'color_grey': product?.contactsCount > 0,'name_red': product.contactsCount == 0 }"
                        (click)="findcontactLinked(product.contactsCount,'Contacts')">
                        {{product.contactsCount}} {{product.contactsCount > 1 ? 'Contacts' : 'Contact'}}</span>
                </mat-list-item>
                <div class="col-10 m-b-10 pointer " *ngIf="showcontact"
                    [ngClass]="{'auto_height': product.contactsCount > 4,'overflow': product.contactsCount < 4 }">
                    <div class="row m-t-10" *ngFor="let contact of getcontact ; let i = index">

                        <span><img style="border-radius: 50%;" width="30" height="30"
                                (click)="ShowContactDetails(contact, 'contact')"
                                [src]="contact.profile ? contact.profile :'assets/avatar.svg'"></span><span
                            (click)="ShowContactDetails(contact, 'contact')" class="p-l-10 fw-600 color_grey">
                            {{contact.firstName|titlecase}} {{contact.lastName|titlecase}}
                        </span>
                    </div>
                </div>

                <!-- Sites -->
                <mat-list-item>
                    <div class="fw-600">
                        <img src="../../../../../assets/images/iconsForsvg/Site_Icon.svg"> {{"Sites"|translate}} :
                    </div>
                    <span class="ml-3 fw-600 color_grey m-t-6 pointer"
                        [ngClass]="{'color_grey': product?.sitesCount > 0,'name_red': product.sitesCount == 0 }"
                        (click)="findSiteLinked(product.sitesCount,'Sites')">
                        {{product.sitesCount}} {{product.sitesCount > 1 ? 'Sites' : 'Site'}}</span>
                </mat-list-item>
                <div class="col-10 pointer" *ngIf="showSite"
                    [ngClass]="{'auto_height': product.sitesCount > 4,'overflow': product.sitesCount < 4 }">
                    <div class="row m-t-10" *ngFor="let site of getSite ; let i = index">
                        <div class="col-2">
                            <img src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
                        </div>
                        <div class="col-10">
                            <span (click)="ShowSiteDetails(site,'site')"
                                class="p-l-10 fw-600 color_grey">{{site.firstName|titlecase}}</span>
                        </div>
                    </div>
                </div>

                <!-- Groups -->
                <mat-list-item>
                    <div class="fw-600">
                        <mat-icon class="red vertical-bottom">people</mat-icon> {{"Groups"|translate}} :
                    </div>
                    <span class="ml-3 fw-600 color_grey m-t-6 pointer"
                        [ngClass]="{'color_grey': product?.groupsCount > 0,'name_red': product.groupsCount == 0 }"
                        (click)="findGroupLinked(product.groupsCount)">
                        {{product.groupsCount}}{{product.groupsCount > 1 ? 'Groups' : 'Group'}}</span>
                </mat-list-item>
                <div class="col-10  p-0 pointer" *ngIf="showGrp"
                    [ngClass]="{'auto_height': product.groupsCount > 4,'overflow': product.groupsCount < 4 }">
                    <div (click)="ShowstaticGroup(grp.id)" class="row  m-l-10 m-t-10 fw-600 color_grey"
                        *ngFor="let grp of getGrp ; let i = index">
                        <mat-icon>group</mat-icon> <span class="m-l-10">{{grp.productGroupName|titlecase}}</span>
                    </div>
                    <div (click)="ShowdynamicGroup(grp.dynamicGroupId)" class="row  m-l-10 m-t-10 fw-600 color_grey"
                        *ngFor="let grp of product?.dynamicGroups ; let i = index">
                        <mat-icon style="color: blue;">group</mat-icon> <span
                            class="m-l-10">{{grp.productGroupName|titlecase}}</span>
                    </div>
                </div>
                <!-- Files -->
                <mat-list-item>
                    <div class="fw-600">
                        <mat-icon class="vertical-bottom" style="color:rgb(66,135,245) ;">insert_drive_file</mat-icon>
                        {{'Files'|translate}} :
                    </div>
                    <span class="ml-3 fw-600 color_grey m-t-6 pointer"
                        [ngClass]="{'color_grey': product?.filesCount > 0,'name_red': product.filesCount == 0 }"
                        (click)="findfilesLinked(product.filesCount)">
                        {{product.filesCount}} {{product.filesCount > 1 ? 'Files' : 'File'}}</span>
                </mat-list-item>
                <div class="col-10  p-0 pointer" *ngIf="showfile"
                    [ngClass]="{'auto_height': product.filesCount > 4,'overflow': product.filesCount < 4 }">
                    <div class="row  m-l-10 fw-600 color_grey" *ngFor="let file of getfile ; let i = index">
                        <mat-icon class="vertical-bottom" style="color:rgb(66,135,245) ;">insert_drive_file</mat-icon>
                        <span (click)="gotofiles(file)">{{file.title|titlecase}}</span>
                    </div>
                </div>
                <!-- Notes -->
                <mat-list-item>
                    <div class="fw-600">
                        <mat-icon class="vertical-bottom" style="color:rgb(255,193,111) ;">speaker_notes</mat-icon>
                        {{"Notes"|translate}} :
                    </div>
                    <span class="ml-3 fw-600 color_grey m-t-6 pointer"
                        [ngClass]="{'color_grey': product?.notesCount > 0,'name_red': product.notesCount == 0 }"
                        (click)="findnotesLinked(product.notesCount)">{{product.notesCount}}
                        {{product.notesCount > 1 ? 'Notes' : 'Note'}}</span>
                </mat-list-item>
                <div class="col-10 p-0 pointer" *ngIf="shownotes"
                    [ngClass]="{'auto_height': product.notesCount > 4,'overflow': product.notesCount < 4 }">
                    <div (click)="ShowNote(notes.id)" class="row  m-l-10 fw-600 color_grey"
                        *ngFor="let notes of getnotes ; let i = index">
                        <mat-icon class="vertical-bottom" style="color:rgb(255,193,111) ;">speaker_notes</mat-icon>
                        <span>{{notes.title|titlecase}}</span>
                    </div>
                </div>


                <!-- Orders -->
                <mat-list-item>
                    <div class="fw-600">
                        <mat-icon class="vertical-bottom" style="color: #8ab2ef;">assignmentt</mat-icon> {{"Orders"|translate}} :
                    </div>
                    <span class="ml-3 fw-600  m-t-6 pointer"
                        [ngClass]="{'color_grey': product?.orderCount > 0,'name_red': product.orderCount == 0 }"
                        (click)="viewOrder(product.orderCount)">
                        {{product?.orderCount > 0 ? 'Last 10 Orders' : '0 Order'}}</span>
                </mat-list-item>

                <div class="col-12 p-0  auto_height" *ngIf="showTransaction">
                    <div class="row m-t-10   fw-600  " *ngFor="let eve of getTransaction ; let i = index">
                        <div class="col-12">
                            <div class="row w-100">
                                <div class="col-2">
                                    <mat-icon
                                        style="vertical-align: middle; color: #8ab2ef; position: relative; top:40px;">assignment</mat-icon>
                                </div>
                                <div class="col-10">
                                    <div class="row w-100">
                                        <span class="fw-600">{{"Order Id"|translate}}: </span>
                                        <span class="fw-600 color_grey">
                                            {{' '+eve.orderId}}
                                        </span>
                                    </div>
                                    <div class="row w-100">
                                        <span class="fw-600">{{"Issue Date"|translate}} : </span>

                                        <span class="fw-600 color_grey ">
                                            {{' '+eve.issueDate | date:'dd-MMM-yyyy H:mm'}}
                                        </span>
                                    </div>
                                    <div class="row w-100">
                                        <span class="fw-600">{{"Due Date"|translate}} : </span>

                                        <span class="fw-600 color_grey ">
                                            {{' '+eve.dueDate | date:'dd-MMM-yyyy H:mm'}}
                                        </span>
                                    </div>
                                    <div class="row w-100">
                                        <span class="fw-600">{{"Total Amount"|translate}} : </span>

                                        <span class="fw-600 color_grey">
                                            {{' '+eve.totalAmount+' '+'€'}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-list-item>
                    <div class="fw-600" (click)="showActivitylogs(product)">
                        <img src="../../../../../assets/images/iconsForsvg/autivity log.svg" alt="">
                        <span *ngIf="product.activityLogCount > 0" class="Activity_badge">{{ product.activityLogCount }}</span>
                        {{'Activity logs'|translate}} 
                    </div>
                    <span (click)="showActivitylogs(product)" class="ml-3 fw-600 color_grey  pointer">
                        <mat-icon>touch_app</mat-icon>
                    </span>
                </mat-list-item> 

                <ng-container class="row m-t-10 m-b-10" style="position: relative; width:430px;"
                    *ngFor="let tags of product.tagViewModels">
                    <ng-container *ngIf="tags!.fieldValue">
                        <div class="mt-3">
                            <span class="fw-600" style="font-size:16px; margin-top: 10px;"
                                *ngIf="tags.fieldValue !== null ">{{tags.fieldName|titlecase}}:
                            </span>
                            <span class="ml-3 fw-600 color_grey" style="font-size:16px;margin-top: 10px;"
                                *ngIf="tags.fieldValue !== ''">
                                {{tags.fieldValue|titlecase}}</span>
                        </div>

                    </ng-container>
                </ng-container>
            </mat-list>
        </section>

    </mat-dialog-content>
</div>

<div class="User-group" *ngIf="productData.type === 'productgroup'">
    <div class="d-flex flex-row align-items-center modal-header">
        <button mat-icon-button mat-dialog-close class="back-arrow">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{"Product Group Details"|translate}}</h2>
        <button mat-icon-button matDialogClose>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="mr-0 ml-0">
        <section class="d-flex flex-column align-items-center mt-3">
            <div class="avatar-70">
                <img src="assets/avatar.svg" />
            </div>
            <h2>{{productData.productGroupName|titlecase}} </h2>
        </section>
        <section class="detail-wrapper">
            <mat-list>
                <mat-list-item *ngFor="let product of productGroup$.products">
                    <div matLine class="fw-600">{{product.productName|titlecase}} </div>
                </mat-list-item>
            </mat-list>
        </section>
    </mat-dialog-content>
</div>

<div class="overlay" *ngIf="spinner.isVisible() | async">
    <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>